import * as React from 'react'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  onClick?: () => void
  children: React.ReactNode
  type?: 'submit' | 'button'
  link?: string
}

export const BUTTON_STYLES = `
  h-12
  px-6
  flex
  text-base
  items-center
  justify-center
  rounded-lg
  transition-all
  duration-300
  bg-blue-600
  text-white
  font-sans-medium
  min-w-[120px]
  hover:bg-blue-500
  focus:outline-none
  focus:ring-2
  focus:scale-95
  shadow-sm
  hover:shadow-lg
  hover:shadow-blue-600/50
  shadow-blue-600/50
`

export const Button = ({
  onClick,
  children,
  type,
  link,
  className,
  ...props
}: ButtonProps) => {
  if (!!link) {
    return (
      <a href={link} className={`${BUTTON_STYLES} ${className} `} {...props}>
        {children}
      </a>
    )
  }

  return (
    <button
      className={`${BUTTON_STYLES} ${className}`}
      {...{ onClick, ...props }}
    >
      {children}
    </button>
  )
}
