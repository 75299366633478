import * as React from 'react'

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string
  multiline?: boolean
}

const TEXTFIELD_STYLES = `
  bg-gray-100
  dark:bg-gray-800
  focus:outline-none
  focus:ring-2
  transition-all
  rounded-lg
  py-2
  px-4
  shadow-sm
  border
  border-gray-200
  dark:border-gray-900
`

export const TextField = ({
  label,
  multiline = false,
  ...props
}: TextFieldProps) => {
  return (
    <label className="flex flex-col mb-4">
      <span className="uppercase tracking-wider text-xs mb-1 block text-gray-600 dark:text-gray-400 font-sans-medium">
        {label}
      </span>
      {multiline ? (
        <textarea
          className={`${TEXTFIELD_STYLES} resize-none h-32`}
          {...props}
        />
      ) : (
        <input className={`${TEXTFIELD_STYLES}`} {...props} />
      )}
    </label>
  )
}
