import * as React from 'react'
import { TextField, Button } from '../components/primitives'
import { Layout } from '../components/Layout'
import { Modal } from '../components/Modal'

interface Message {
  name: string
  email: string
  message: string
  id: number
}

const Contact = () => {
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [showModal, setShowModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const clearForm = () => {
    setName('')
    setEmail('')
    setMessage('')
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()

    if (!name || !email || !message) {
      return
    }

    try {
      setLoading(true)
      await fetch(
        `${process.env.GATSBY_API_URL}/sendMail?name=${name}&email=${email}&message=${message}`,
        {
          method: 'POST',
        }
      )
      setShowModal(true)
    } catch (err) {
      console.log(err)
    }

    clearForm()
    setLoading(false)
  }

  return (
    <Layout
      title="Contact"
      description="Contact Darren Galway a software designer &amp; developer based out of Waterloo, Ontario, Canada."
    >
      <Modal show={showModal} close={() => setShowModal(false)}>
        <div className="text-center mb-8">
          <p className="text-[6rem]">✌️</p>
          <p className="text-4xl mb-4 font-sans-bold">
            Thank you for reaching out!
          </p>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            If you're not a bot I'll make sure to respond to you as soon as
            possible.
          </p>
        </div>
      </Modal>

      <main className="container max-w-3xl mx-auto px-4 animate-in pt-12 lg:pt-24 pb-12 lg:pb-0">
        <div className="mb-8">
          <h1 className="text-2xl mb-2 font-sans-bold">Get in touch</h1>
          <p className="text-xl text-gray-700 dark:text-gray-400">
            Want to chat? Get in touch via the contact form, or send me an email
            directly at{' '}
            <a
              className="text-blue-600 hover:underline transition-all hover:text-blue-500 underline-offset-2"
              href="mailto:darrengalwaydesign@gmail.com?subject=Darren Galway website inquiry"
            >
              darrengalwaydesign@gmail.com
            </a>
          </p>
        </div>
        <form onSubmit={onSubmit} className="w-full">
          <fieldset>
            <TextField
              required
              label="Name"
              value={name}
              disabled={loading}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              required
              label="Email"
              value={email}
              disabled={loading}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            <TextField
              required
              label="Message"
              value={message}
              disabled={loading}
              onChange={(e) => setMessage(e.target.value)}
              multiline
            />
            <Button disabled={loading}>
              {loading ? 'Sending it...' : 'Send it'}
            </Button>
          </fieldset>
        </form>
      </main>
    </Layout>
  )
}

export default Contact
