import React, { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useEscAway, useClickAway } from '../hooks'
import { Button } from '../components/primitives'

type ModalProps = {
  children: React.ReactNode | React.ReactNode[]
  close: () => void
  show: boolean
}

export const Modal = ({ close, show = false, children }: ModalProps) => {
  const node = useRef(null)

  useEscAway(node, () => close())
  useClickAway(node, () => close())

  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames="modal"
      unmountOnExit
      mountOnEnter
    >
      <div className="bg-gray-50/80 dark:bg-gray-900/80 p-4 z-50 flex items-center justify-center fixed inset-0">
        <div
          ref={node}
          className="w-[36rem] margin-auto p-6 flex flex-col items-center"
        >
          {children}
          <Button onClick={close}>Close</Button>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Modal
